import "core-js/modules/es.array.push.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "top1 max-652"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "title"
};
const _hoisted_6 = {
  class: "balan"
};
const _hoisted_7 = {
  class: "progress acea-row row-between-wrapper"
};
const _hoisted_8 = {
  class: "top2"
};
const _hoisted_9 = {
  class: "isLinks acea-row row-between-wrapper"
};
const _hoisted_10 = {
  class: "chgeLists max-652"
};
const _hoisted_11 = {
  class: "center"
};
const _hoisted_12 = {
  class: "title"
};
const _hoisted_13 = {
  class: "contents acea-row row-between-wrapper"
};
const _hoisted_14 = {
  class: "left"
};
const _hoisted_15 = {
  class: "rit"
};
const _hoisted_16 = {
  class: "notList"
};
const _hoisted_17 = {
  class: "lists"
};
const _hoisted_18 = {
  key: 0
};
import { router } from "@/router";
import { pngHashMap } from "@/assets/levelmg";
import { useUserStore } from "@/stores/user";
import { computed, onMounted, ref } from "vue";
import NP from 'number-precision';
export default {
  __name: 'level',
  setup(__props) {
    const userStore = useUserStore();
    userStore.updateUser();
    userStore.getBalance();
    const vipList = ref([{
      name: 'LV0'
    }, {
      name: 'LV1',
      isUsdt: 1000,
      getU: 18,
      chgeList: []
    }, {
      name: 'LV2',
      isUsdt: 5000,
      getU: 88,
      chgeList: []
    }, {
      name: 'LV3',
      isUsdt: 10000,
      getU: 588,
      chgeList: ['levelInfo.infoLv1']
    }, {
      name: 'LV4',
      isUsdt: 50000,
      getU: 1888,
      chgeList: ['levelInfo.infoLv1', 'levelInfo.infoLv2']
    }, {
      name: 'LV5',
      isUsdt: 100000,
      getU: 8888,
      chgeList: ['levelInfo.infoLv1', 'levelInfo.infoLv2']
    }, {
      name: 'LV6',
      isUsdt: 300000,
      getU: 18888,
      chgeList: ['levelInfo.infoLv1', 'levelInfo.infoLv2']
    }, {
      name: 'LV7',
      isUsdt: 500000,
      getU: 28888,
      chgeList: ['levelInfo.infoLv1', 'levelInfo.infoLv2', 'levelInfo.infoLv3']
    }]);
    const back = () => {
      router.go(-1);
    };
    const levelImg = item => {
      return pngHashMap.get(item).icon;
    };
    const userinfo = computed(() => userStore.userinfo);
    const walletBalance = computed(() => userStore.walletBalance);
    const regInfo = ref(0);
    const currentLevel = ref(0);
    const nextLevel = ref(0);
    const nextRow = ref({});
    onMounted(() => {
      let vip = userinfo.value.vip;
      currentLevel.value = vip === 7 ? 6 : vip;
      nextLevel.value = vip === 7 ? 7 : vip + 1;
      let b = parseFloat(walletBalance.value.recharge_amount);
      console.log(userinfo.value.vip + 1);
      let a = vipList.value[userinfo.value.vip + 1].isUsdt;
      regInfo.value = NP.times(NP.divide(b, a), 100) > 100 ? 100 : NP.times(NP.divide(b, a), 100);
      nextRow.value = vipList.value[nextLevel.value];
    });
    const charOpens = () => {
      ssq.push('chatOpen');
    };
    return (_ctx, _cache) => {
      var _walletBalance$value;
      const _component_van_progress = _resolveComponent("van-progress");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("img", {
        src: levelImg(`lb${currentLevel.value}`),
        alt: ""
      }, null, 8, _hoisted_2), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: levelImg(`lv${currentLevel.value}`),
        alt: ""
      }, null, 8, _hoisted_4), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('Cumulative')), 1), _createElementVNode("p", _hoisted_6, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls((_walletBalance$value = walletBalance.value) === null || _walletBalance$value === void 0 ? void 0 : _walletBalance$value.recharge_amount)) + " ", 1), _cache[0] || (_cache[0] = _createElementVNode("span", null, "฿", -1))]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, "VIP" + _toDisplayString(currentLevel.value), 1), _createElementVNode("div", null, "VIP" + _toDisplayString(nextLevel.value), 1)]), _createVNode(_component_van_progress, {
        color: "#FDE454",
        "pivot-text": "",
        percentage: regInfo.value
      }, null, 8, ["percentage"])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(vipList.value, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass({
            'active': currentLevel.value >= index,
            'rdsdt': currentLevel.value === index
          })
        }, [_cache[1] || (_cache[1] = _createElementVNode("div", {
          class: "link"
        }, null, -1)), _cache[2] || (_cache[2] = _createElementVNode("div", {
          class: "recod"
        }, [_createElementVNode("div")], -1)), _createElementVNode("div", {
          class: _normalizeClass(["names", {
            'colors': currentLevel.value === index
          }])
        }, _toDisplayString(item.name), 3)], 2);
      }), 128))]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [_createTextVNode(_toDisplayString(_ctx.$t('levelInfo.toUpUsd', {
        name: nextRow.value.name
      })) + ": ", 1), _createElementVNode("span", null, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls(nextRow.value.isUsdt)) + " ", 1), _cache[3] || (_cache[3] = _createElementVNode("span", null, "฿", -1))])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, [_createElementVNode("p", null, [_createTextVNode(_toDisplayString(_ctx.$public.keepFulls(nextRow.value.getU)) + " ", 1), _cache[4] || (_cache[4] = _createElementVNode("span", null, "฿", -1))]), _createElementVNode("p", null, _toDisplayString(_ctx.$t('赠送')), 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("p", null, _toDisplayString(nextRow.value.name) + " " + _toDisplayString(_ctx.$t('大礼包')), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('联系客服领取')), 1)])]), _createElementVNode("div", _hoisted_16, [nextRow.value.chgeList && nextRow.value.chgeList.length > 0 ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 0
      }, _renderList(nextRow.value.chgeList, item => {
        return _openBlock(), _createElementBlock("div", _hoisted_17, [_createVNode(_component_van_icon, {
          name: "success"
        }), _createElementVNode("span", null, _toDisplayString(_ctx.$t(item)), 1)]);
      }), 256)) : _createCommentVNode("", true)])])])]), _createElementVNode("div", {
        class: _normalizeClass([{
          'isWidts': currentLevel.value > 2
        }, "buttons acea-row row-between-wrapper max-652"])
      }, [_createElementVNode("div", null, [_createVNode(_component_van_button, {
        to: "/me?type=0&info=2",
        color: "#56F9AA",
        round: "",
        block: "",
        style: {
          "color": "#333"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('levelSets')), 1)]),
        _: 1
      })]), currentLevel.value > 2 ? (_openBlock(), _createElementBlock("div", _hoisted_18, [_createVNode(_component_van_button, {
        color: "#56F9AA",
        round: "",
        plain: "",
        onClick: charOpens,
        style: {
          "color": "#fff",
          "background": "none"
        }
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('联系客服领取')), 1)]),
        _: 1
      })])) : _createCommentVNode("", true)], 2), _cache[5] || (_cache[5] = _createElementVNode("div", {
        style: {
          "height": "30px"
        }
      }, null, -1))], 64);
    };
  }
};